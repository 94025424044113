import React, {Component} from 'react'
import fire from './../config/fire';
import logo from '../images/minniesLogo.PNG';

class Login extends Component {
    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
      
        this.state = {
            email : '',
            password : ''
        }
       

    }

    login(e) {
     e.preventDefault();
     fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((u) => {
     }).catch((error) => {alert(error)});


    }

    handleChange(e) {
       
        this.setState({ [e.target.name]: e.target.value });
    }

    

    render() {
        const doCenter = {
            textAlign: 'center'
            };
        return (
            <div>
            <div className="row">
            <div className="col-sm-3">
            <img src={logo} alt="Logo" />
            </div>
            <div className="col-sm-6">
                <br></br>
            <h5 style={doCenter}>Minnie's New Volunteer/Guest Admin Management Page</h5>
            </div>
            </div>
            <form>
                <div style={doCenter}>
            <input type="email" name="email" value={this.state.email} onChange={this.handleChange} placeholder="enter your email" />
            <br></br>
            <input type="password" name="password" value={this.state.password} onChange={this.handleChange} placeholder="enter password" />
            <br></br>
            <input type="submit" onClick={this.login} value="Login" />
            </div>
          </form>
          </div>
            )
    }
}

export default Login