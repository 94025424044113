import React, { Component } from 'react'
import Loader from 'react-loader-spinner'
import { CSVLink } from "react-csv";
import 'react-table/react-table.css'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import logo from '../images/minniesLogo.PNG';
import fire from './../config/fire';
import sortBy from 'lodash/sortBy';

class MinniesGuestVolunteerAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
            myPic: "",
            cvsFileDate: "NewVolenteers-" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".csv",
            showWaitSpinner: true,
            selectedId: 0,
            isValid: false,
            isReadOnly : false,
            selectedFirstName: "",
            selectedLastName: "",
            selectedAddress1: "",
            selectedAddress2: "",
            selectedCity: "",
            selectedState: "",
            selectedZip: "",
            selectedEmail: "",
            selectedPhone: "",
            selectedDOB: "",
            selectedGender: "",
            selectedOrg: "",
            selectedCrimeYN: "",
            selectedCrimeExplain: "",
            selectedOtherLanguages: "",
            guest_hits: [],
            myPic: "",
            guest_cvsFileDate: "NewGuests-" + new Date().toLocaleDateString() + ".csv",
            guest_showWaitSpinner: true,
            guest_selectedId: 0,
            guest_isValid: false,
            guest_selectedFirstName: "",
            guest_selectedMiddleName: "",
            guest_selectedLastName: "",
            guest_selectedAddress: "",
            guest_selectedCity: "",
            guest_selectedState: "",
            guest_selectedZip: "",
            guest_selectedEmail: "",
            guest_selectedSubmitDate: "",
            guest_selectedLastValidatedDate: ""

        };

    }

    toggleValidationForGuest() {

        fetch('https://www.invisionthat.com/minnies/api/values/toggleValidation?id=' + this.state.guest_selectedId + "&value=" + this.refs.validCheckBox.checked.toString(), {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        })
            .then(function (response) {
                return response.json();
            })
            .then(myJson => {

                this.setState({  guest_selectedLastValidatedDate : myJson.validationDate,    showWaitSpinner: false, guest_isValid: !this.state.guest_isValid })
                this.getGuests();
            });

    }

    getGuests() {
        fetch('https://www.invisionthat.com/minnies/api/values/returnguestsnew', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        })
            .then(function (response) {

                return response.json();
            })
            .then(myJson => {
                var sortedArray =  sortBy(myJson,(item) => {return item.lastName })
                this.setState({ guest_hits: sortedArray, guest_showWaitSpinner: false })
                //console.log(JSON.stringify(myJson));
            });

    }

    getGuestPicAndInfo(guestObj) {

        this.setState({
            guest_selectedId: guestObj.guestId, guest_showWaitSpinner: true,guest_selectedLastValidatedDate : guestObj.guestValidDateTime,
            guest_selectedFirstName: guestObj.firstName, guest_selectedMiddleName: guestObj.middleName, guest_selectedLastName: guestObj.lastName,
            guest_selectedAddress: guestObj.streetAddress, guest_selectedCity: guestObj.city,
            guest_selectedState: guestObj.state, guest_selectedZip: guestObj.zip, guest_selectedEmail: guestObj.email, guest_selectedSubmitDate: guestObj.guestSubmitDate
        })
        fetch('https://www.invisionthat.com/minnies/api/values/ReturnGuestPicString?id=' + guestObj.guestId, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        })
            .then(function (response) {
                return response.json();
            })
            .then(myJson => {
                var myElement = this.state.guest_hits.find(ele => { return guestObj.guestId == ele.guestId });
                var isChecked = myElement.isValidated == "true" ? true : false;
                this.setState({ myPic: myJson.value, guest_showWaitSpinner: false, guest_isValid: isChecked })
                this.getGuests();
            });
    }

    removeGuest(id) {

        if (window.confirm("Are you sure you want to delete this Guest record?")) {
            this.setState({ guest_selectedId: 0 })
            fetch('https://www.invisionthat.com/minnies/api/values/RemoveGuest?id=' + id, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'get'
            })
                .then((response) => {
                    this.getGuests();

                })
        }
    }

 
    componentDidMount() {

        var urlParams = new URLSearchParams(window.location.search);
        var viewOnlyFlag = urlParams.get('readonly');
        
        if(viewOnlyFlag != null && viewOnlyFlag != "" && viewOnlyFlag == "yes") {
            
            var d = new Date();
            var currentHour = d.getHours();
            var currentDay = d.getDay();
            if(currentDay >= 1 && currentDay <= 6 && currentHour >= 8 && currentHour <= 12)
             {
                this.state.isReadOnly = true;
                setTimeout(() => {
                    fire.auth().signOut();
                    alert('Session Expired.');
                }, 14400000);
                alert('ReadOnly mode has been turned on Successfully.');  
             }
             else {
                fire.auth().signOut();
                alert('System not available at this time');
                
             }
        }
        this.state.guest_showWaitSpinner = true;
        this.state.showWaitSpinner = true;
        // this.setState({showWaitSpinner : true});
        this.getVolunteers();
        this.getGuests();
        // this.getCheckBoxDefaultValue();
    }

    componentWillUnmount() {
        fire.auth().signOut();

    }

    getCheckBoxDefaultValue() {

    }

    getVolunteers() {
        fetch('https://www.invisionthat.com/minnies/api/values/ReturnVolenteers', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        })
            .then(function (response) {

                return response.json();
            })
            .then(myJson => {
               var sortedArray =  sortBy(myJson,(item) => {return item.lastName })
                this.setState({ hits: sortedArray, showWaitSpinner: false })
        
            });

    }

    getVolunteerInfo(Obj) {

        this.setState({
            selectedId: Obj.volunteerId,
            selectedFirstName: Obj.firstName, selectedLastName: Obj.lastName,
            selectedAddress1: Obj.streetAddress1, selectedAddress2: Obj.streetAddress2, selectedCity: Obj.city,
            selectedState: Obj.state, selectedZip: Obj.zip, selectedEmail: Obj.email, selectedPhone: Obj.cellPhone,
            selectedDOB: Obj.dob, selectedGender: Obj.gender, selectedOrg: Obj.organization,
            selectedOtherLanguages: Obj.languagesSpoken
        });
    }

    removeVolunteer(id) {
        if (window.confirm("Are you sure you want to delete this Volunteer record?")) {
        this.setState({ selectedId: 0 })
        fetch('https://www.invisionthat.com/minnies/api/values/RemoveVolunteer?id=' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        })
            .then((response) => {
                this.getVolunteers();

            })
        }

    }

    removeAllVolunteers() {
        if (window.confirm("Are you sure you want to delete ALL Volunteers?  There's no way to get them back if you do.")) {
        this.setState({ selectedId: 0 })
        fetch('https://www.invisionthat.com/minnies/api/values/ClearAllVolunteers', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        })
            .then((response) => {
                this.getVolunteers();

            })
        }

    }

    removeAllGuests() {
        if (window.confirm("Are you sure you want to delete ALL Guests?  There's no way to get them back if you do.")) {
        this.setState({ selectedId: 0 })
        fetch('https://www.invisionthat.com/minnies/api/values/ClearAllGuest', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'get'
        })
            .then((response) => {
                this.getGuests();

            })
        }

    }

    render() {

        const GuestHeaders = [
            { label: "1", key: "guestId" },
            { label: "Last Name", key: "lastName" },
            { label: "First Name", key: "firstName" },
            { label: "Middle Name", key: "middleName" },
            { label: "Date of Birth(m/d/Y)", key: "birthDay" },
            { label: "Gender(M/F)", key: "gender" },
            { label: "Email", key: "email" },
            { label: "Mobile Phone", key: "cellPhone" },
            { label: "Home Phone", key: "cellPhone" },
            { label: "Address 1", key: "streetAddress" },
            { label: "Address 2", key: "" },
            { label: "City", key: "city" },
            { label: "State", key: "state" },
            { label: "Zip Code", key: "zip" },
            { label: "Opt-in for text messages (Y/N)", key: "" },
            { label: "Referral", key: "referredByName" },
            { label: "Ethnicity", key: "ethnicity" },
            { label: "Military Service?(Y/N)", key: "militaryYN" },
            { label: "Active(Y/N)", key: "activeYN" },
            { label: "Non-Active (Y/N)", key: "nonActiveYN" },
            { label: "Veteran (Y/N)", key: "veteranYN" },
            { label: "Employed? (Y/N)", key: "employeeStatus" },
            { label: "Monthly Income", key: "monthlyIncome" },
            { label: "Disabled (Y/N)", key: "disabledYN" },
            { label: "SNAP/Food Stamp (Y/N)", key: "foodStampsYn" },
            { label: "Children Reduced Lunch (Y/N)", key: "reduceLunchYn" },
            { label: "Wants to Learn about SNAP (Y/N)", key: "learnSnapYn" },
            { label: "PAN Receipient? (Y/N)", key: "" },
            { label: "PAN Receipient #", key: "" },
            { label: "# in Household", key: "" },
            { label: "Last Validated Date", key: "guestValidDate" }
        ];

        const VolunteerHeaders = [
            { label: "1", key: "volunteerId" },
            { label: "Last Name", key: "lastName" },
            { label: "First Name", key: "firstName" },
            { label: "Organization", key: "organization" },
            { label: "Mobile Phone", key: "cellPhone" },
            { label: "Email", key: "email" },
            { label: "DOB (m/d/Y)", key: "dob" },
            { label: "Gender(M/F)", key: "gender" },
            { label: "Address 1", key: "streetAddress1" },
            { label: "Address 2", key: "streetAddress2" },
            { label: "City", key: "city" },
            { label: "State", key: "state" },
            { label: "Zip Code", key: "zip" },
            { label: "Opt-in for text messages (Y/N)", key: "optInText" },
            { label: "Years Active", key: "yearsActive" },
            { label: "Languages Spoken (Comma seperated values)", key: "languagesSpoken" },
        ];

        const { hits } = this.state;
        const { guest_hits } = this.state;

        const divStyle = {
            margin: '0px',
            padding: '3px'
        };

        const addBordering = {
            border: '1px solid'
        };

        const zipWidth = {
            width: '100px',
            border: '1px solid'
        };

        const parent = {
            position: 'relative',
        };

        const colPadding = {
            paddingRight: '13px',
        };

        const imageCenter = {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
        };
        return (
            
            <div>
                {
                    this.state.showWaitSpinner ? <Loader type="Puff" color="#00BFFF" height="100" width="100" /> : null
                }
                <div className="row">
                    <div className="col-sm-2">
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className="col-sm-10">
                        <h5>Minnie's Volunteer/Guest Admin Management Page</h5>
                    </div>
                </div>


                <Tabs>
                    <TabList>
                        <Tab>Volunteer</Tab>
                        <Tab>Guest</Tab>
                    </TabList>

                    <TabPanel>
                        <h6>Click Name to Show Volunteer Submitted Info</h6>
                        <button type="button" onClick={() => { this.getVolunteers() }} class="btn btn-primary">Refresh Volunteer List</button>
                        <button type="button" hidden={this.state.isReadOnly == true ? true : false} onClick={() => { this.removeAllVolunteers() }} class="btn btn-danger">Remove All Volunteers</button>
                    <br></br><br></br>
                        <table styleName="border : solid">
                            <tbody>
                                <tr>
                                    <th style={colPadding}>First Name</th>
                                    <th style={colPadding}>Last Name</th>
                                    <th style={colPadding}>Phone</th>
                                </tr>
                                {
                                    hits.map((value) => (
                                        <tr >
                                            <td style={colPadding} onClick={() => { this.getVolunteerInfo(value) }}>{value.firstName}</td>
                                            <td style={colPadding} onClick={() => { this.getVolunteerInfo(value) }}>{value.lastName}</td>
                                            <td style={colPadding} onClick={() => { this.getVolunteerInfo(value) }}>{value.cellPhone}</td>
                                            <td>
                                                <button hidden={this.state.isReadOnly == true ? true : false} type="button" onClick={() => { this.removeVolunteer(value.volunteerId) }} class="btn btn-danger">Remove</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                        <h3 hidden={this.state.selectedId == 0 ? true : false}>Selected Volunteer Info:</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <ul hidden={this.state.selectedId == 0 ? true : false}>
                                    <li>First Name:  {this.state.selectedFirstName}</li>
                                    <li>Last Name:   {this.state.selectedLastName}</li>
                                    <li>Address1:    {this.state.selectedAddress1}</li>
                                    <li>Address2:    {this.state.selectedAddress2}</li>
                                    <li>City:        {this.state.selectedCity}</li>
                                    <li>State:       {this.state.selectedState}</li>
                                    <li>Zip:         {this.state.selectedZip}</li>
                                    <li>Email:       {this.state.selectedEmail}</li>
                                    <li>Phone:       {this.state.selectedPhone}</li>
                                    <li>Birthday:    {this.state.selectedDOB}</li>
                                    <li>Gender:      {this.state.selectedGender}</li>
                                    <li>Group/Organization:  {this.state.selectedOrg}</li>
                                    <li>Other Languages Spoken:  {this.state.selectedOtherLanguages}</li>
                                </ul>
                            </div>
                        </div>
                        <br></br>

                        <CSVLink hidden={this.state.isReadOnly == true ? true : false}  data={hits} headers={VolunteerHeaders} filename={this.state.cvsFileDate}
                            className="btn btn-success" >
                            Generate Volunteer CSV File
              </CSVLink>
                    </TabPanel>


                    <TabPanel>
                    <h6>Click Name to show Guest proof of residence picture and Info</h6>
                    <button type="button" onClick={() => { this.getGuests() }} class="btn btn-primary">Refresh Guest List</button>
                    <button type="button" hidden={this.state.isReadOnly == true ? true : false} onClick={() => { this.removeAllGuests() }} class="btn btn-danger">Remove All Guests</button>
                        <br></br><br></br>
                        <div>
                            {
                                this.state.guest_showWaitSpinner ? <Loader type="Puff" color="#00BFFF" height="100" width="100" /> : null
                            }

                            <table styleName="border : solid">
                                <tbody>
                                    <tr>
                                        <th style={colPadding}>First Name</th>
                                        <th style={colPadding}>Last Name</th>
                                        <th style={colPadding}>Zip</th>
                                        <th style={colPadding}>Guest Valid?</th>
                                    </tr>
                                    {
                                        guest_hits.map((value) => (
                                            <tr >
                                                <td style={colPadding} onClick={() => { this.getGuestPicAndInfo(value) }}>{value.firstName}</td>
                                                <td style={colPadding} onClick={() => { this.getGuestPicAndInfo(value) }}>{value.lastName}</td>
                                                <td style={colPadding} onClick={() => { this.getGuestPicAndInfo(value) }}>{value.zip}</td>
                                                <td style={colPadding} onClick={() => { this.getGuestPicAndInfo(value) }}>{value.isValidated == "true" ? "YES" : "NO"}</td>
                                                <td>
                                                    <button hidden={this.state.isReadOnly == true ? true : false} type="button" onClick={() => { this.removeGuest(value.guestId) }} class="btn btn-danger">Remove</button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <br />
                            <br />
                            <h2>Selected Guest's Proof Of Residence Picture</h2>
                            <div className="row">
                                <div className="col-sm-6">
                                    <img hidden={this.state.guest_selectedId == 0 ? true : false} src={`data:image/jpeg;base64,${window.atob(this.state.myPic)}`} />
                                </div>
                                <div className="col-sm-6">
                                    <input type="checkbox" ref="validCheckBox" name="isValid" hidden={this.state.guest_selectedId == 0 ? true : false} checked={this.state.guest_isValid} onChange={() => { this.toggleValidationForGuest() }} id="isValid" />
                                    <label hidden={this.state.guest_selectedId == 0 ? true : false} for="isValid">&nbsp;Is Guest Validated</label>
                                    <ul hidden={this.state.guest_selectedId == 0 ? true : false}>
                                        <li>First Name: {this.state.guest_selectedFirstName}</li>
                                        <li>Middle Name: {this.state.guest_selectedMiddleName}</li>
                                        <li>Last Name: {this.state.guest_selectedLastName}</li>
                                        <li>Address:  {this.state.guest_selectedAddress}</li>
                                        <li>City:  {this.state.guest_selectedCity}</li>
                                        <li>State:  {this.state.guest_selectedState}</li>
                                        <li>Zip:  {this.state.guest_selectedZip}</li>
                                        <li>Email:  {this.state.guest_selectedEmail}</li>
                                        <li>App Submit Date:  {this.state.guest_selectedSubmitDate}</li>
                                        <li>Validated Date:  {this.state.guest_selectedLastValidatedDate == null ? "" : this.state.guest_selectedLastValidatedDate.toString() }</li>
                                    </ul>
                                </div>
                            </div>
                            <br></br>

                            <CSVLink hidden={this.state.isReadOnly == true ? true : false} data={guest_hits} headers={GuestHeaders} filename={this.state.guest_cvsFileDate}
                                className="btn btn-success" >
                                Generate Guest CSV File
 </CSVLink>

                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </div>

                    </TabPanel>
                </Tabs>

            </div>
        )
    }
}


export default MinniesGuestVolunteerAdmin