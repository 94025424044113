import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyBhLqFRBdOGOvGgtSLnaMvT4R94W3-fL3M",
  authDomain: "minniesadminpage.firebaseapp.com",
  databaseURL: "https://minniesadminpage.firebaseio.com",
  projectId: "minniesadminpage",
  storageBucket: "",
  messagingSenderId: "407522605267",
  appId: "1:407522605267:web:3cf15be8a700536c"
};
  const fire = firebase.initializeApp(config);
  export default fire;