import React, {Component} from 'react';
import fire from './config/fire'
import Login from './components/Login';
import MinniesGuestVolunteerAdmin from './components/MenniesGuestVolunteerAdmin';


class LoginManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user:{}
        };
    
      }

      doSomethingBeforeUnload = () => {
        fire.auth().signOut().then(function() {
          
          }, function(error) {
            console.error('Sign Out Error', error);
          });
    }

    // Setup the `beforeunload` event listener
    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.doSomethingBeforeUnload();
        });
    };
    
      componentDidMount() {
        this.setupBeforeUnloadListener();
          this.authListener();
      }

      componentWillUnmount() {
       
    
      }
    

      authListener() {
        fire.auth().onAuthStateChanged((user) => {

            if(user){
                this.setState({user});
                
            }
            else{
                this.setState({user:null});
                
            }
        });

      }

      render() {
return (

<div>

{this.state.user ? (<MinniesGuestVolunteerAdmin />) : (<Login />)}

</div>

);

      }

}

export default LoginManager